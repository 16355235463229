import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueMasonry from 'vue-masonry-css'

Vue.use(VueMasonry);

Vue.config.productionTip = false
Sentry.init({
  Vue,
  dsn: "https://273dd181aa65409eab967fa3b1e262b7@o213028.ingest.sentry.io/5527861",
  environment: process.env.NODE_ENV,
  release: process.env.VUE_APP_RELEASE,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", ".thesegirlz.com", /^\//],
    }),
  ],
  tracesSampleRate: 0.5,
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
